import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Apps from '../scenes/layouts';
import { adminRoutesData } from './adminRoutesData';
import { FilterProvider } from '../scenes/context/filterContext';
import { EditProvider } from '../scenes/context/editContext';
import _R from '../components/api';
import { AuthContext } from '../scenes/context/authContext';

const AdminRoutes = ({ session, user }) => {

    const { setPermissions, permissions } = useContext(AuthContext)
    
    useEffect(() => {
        const getPermissions = async () => {
            try {
                const role = user.role
                const result = await _R.get('getRolePermissions', { params: { role } })
                setPermissions(result.data)
            } catch (error) {
                console.error(error)
            }
        }
        getPermissions();
    }, [user])

    return (
        <FilterProvider>
            <EditProvider>
                <Apps user={user} session={session}>
                    <Routes>
                        {adminRoutesData.map((element, index) => (
                            permissions.includes(element.permission) &&
                            element.routes.map((r, ind) => {
                                return (
                                    <Route key={ind + 1} exact path={r.path} element={r.element} />
                                )
                            })
                        ))}
                    </Routes>
                </Apps>
            </EditProvider>
        </FilterProvider>
    );
};

export default AdminRoutes;
