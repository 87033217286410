import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext';
import { BsFillDoorOpenFill, BsGrid3X3Gap } from 'react-icons/bs'
import _R from "../../../components/BaseUrl"
import {app_path as logos_path  } from '../../../paths';


function Header() {
  const { isAuth, logout, user } = useContext(AuthContext);
  const [sessi, setSessi] = useState(null)
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const trigger = useRef(null);
  const mobileNav = useRef(null); 

  useEffect(() => {
        _R.get("/getSession").then(res=>{
            setSessi(res.data)
        })
  }, []);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

 

  const handleLogout = () => {
    logout();
  }

  return (
    <header className="sticky bg-slate-900  w-full z-30 top-0">
      <div className=" mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-[70px]">
          <div className="shrink-0 mr-4 flex items-center">
            <Link to="/" className="block mx-2" aria-label="Cruip">
              <img className="w-10  h-10 fill-current text-purple-600" src={`${logos_path}logo.png`} alt="" />
            </Link>
            <Link to="/" className="block mx-2" aria-label="Cruip">
              <img className="w-10  h-10 fill-current text-purple-600 opacity-60" src={`${logos_path}ceil.png`} alt="" />
            </Link>
            <div className='mx-2 text-gray-100 px-2 border-l border-gray-100'>
              <p className=' text-sm'>
                Session : <span className='text-xs'>{sessi ? `${sessi.name} ${sessi.year}` : ''}</span>
              </p>
            </div>
          </div>
          <nav className="hidden md:flex md:grow">
            <ul className="flex grow justify-end flex-wrap items-center">
              {
                !isAuth ? (
                  <>
                    <li>
                      <Link to="/en/signin" className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out">Sign in</Link>
                    </li>
                    <li>
                      <Link to="/en/signup" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">Sign up</Link>
                    </li>
                  </>
                ) : (

                  <>
                    {user && user.role && user.role != 'student' &&
                      <li>
                        <Link to="/en/admin/dashboard" className="font-medium text-purple-600 hover:text-purple-800 px-4 py-3 flex items-center transition duration-150 ease-in-out">Console<BsGrid3X3Gap className='ml-2' /></Link>
                      </li>
                    }
                    <li>
                      <Link to={''} onClick={() => handleLogout()} className="font-medium text-slate-500 hover:text-red-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">Sign out <BsFillDoorOpenFill className='ml-2' /></Link>
                    </li>
                  </>
                )
              }

            </ul>
          </nav>
          <div className="md:hidden">
            <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>
            <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 }}>
              <ul className="bg-gray-800 px-4 py-2">
                {
                  !isAuth ? (
                    <>
                      <li>
                        <Link to="/en/signin" className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center">Sign in</Link>
                      </li>
                      <li>
                        <Link to="/en/signup" className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out">Sign up</Link>
                      </li>
                    </>) : (
                    <>
                      {user && user.role && user.role != 'student' &&
                        <li>
                          <Link to="/en/admin/dashboard" className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center">Console<BsGrid3X3Gap className='ml-2' /></Link>
                        </li>}

                      <li>
                        <Link to={''} onClick={() => handleLogout()} className="flex font-medium w-full text-red-600 hover:text-red-800 px-2 py-3  justify-center transition duration-150 ease-in-out">Sign out<BsFillDoorOpenFill className='ml-2' /></Link>
                      </li>
                    </>
                  )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
