import React, { PureComponent } from "react";
import { FixedSizeList as List } from "react-window";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { BsFillTicketPerforatedFill } from "react-icons/bs";

export default class Table extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterType: 1,
            columnWidths: this.props.widths,
            rows: this.props.rows
        };
        this.headerRef = React.createRef();
        this.bodyRef = React.createRef();
    }

    componentDidMount() {
        console.log(this.state.rows)
    }



    handleSort = (field) => {
        const { filterType } = this.state;
        const sortedRows = [...this.props.rows];
        sortedRows.sort((a, b) => {
            const aValue = field === "created_at" ? new Date(a[field]) : a[field];
            const bValue = field === "created_at" ? new Date(b[field]) : b[field];
            return (
                field == "created_at" ?
                    (bValue - aValue) * filterType :
                    (
                        bValue < aValue ? -1 * filterType :
                            bValue > aValue ? 1 * filterType : 0
                    )
            );
        });
        this.setState({ filterType: -filterType });
        this.setState({ rows: sortedRows })
        this.props.setRows(sortedRows);
    };
    handleFilter = (rows) => {
        if ((this.props.filter) != "")
            return (
                rows.filter(
                    row => (
                        row['email'] && (row['email'].toString().toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1)
                        || row['firstname'] && (row['firstname'].toString().toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1)
                        || row['lastname'] && (row['lastname'].toString().toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1)
                        || row['phone'] && (row['phone'].toString().indexOf(this.props.filter) > -1)
                    )
                ))
        return rows
    }
    renderCell = (row, column, index, colWidth) => {
        const fieldValue = column.subfield && column.subfield != '' ? row[column.field][column.subfield] : row[column.field];
        const cellStyle = { width: `${colWidth}px`, minWidth: `${colWidth}px`, color: column.color };

        switch (column.type) {
            case "boolean":
                return (
                    <div style={cellStyle} className="border border-slate-600 px-4 py-1">
                        <span
                            className={`text-xs px-2 py-1 rounded-lg ${fieldValue
                                ? "bg-green-700 text-white"
                                : "bg-red-700 text-white"
                                }`}
                        >
                            {fieldValue ? "Yes" : "No"}
                        </span>
                    </div>
                );
            case "file":
                let path = (this.props.model == "demands") ?
                    `https://ceil.univ-msila.dz/en/images/isUnivS/${fieldValue}`
                    :
                    `https://ceil.univ-msila.dz/en/images/idc/${fieldValue}`;

                return (
                    <div style={cellStyle} className="border border-slate-600 px-4 py-1">
                        {fieldValue ? (
                            <Link
                                className="text-green-600 font-bold"
                                to={path}
                                target="_blank"
                            >
                                View
                            </Link>
                        ) : (
                            <span className="text-red-300">Not Found!</span>
                        )}
                    </div>
                );
            case "list":
                return (
                    <div
                        style={cellStyle}
                        className={`border border-slate-600 px-4 py-1 ${fieldValue === "Pending"
                            ? "text-yellow-500"
                            : fieldValue === "Rejected"
                                ? "text-red-500"
                                : "text-green-500"
                            }`}
                    >
                        {fieldValue}
                    </div>
                );
            case "button":
                const keySearch =  ['users','groups'].includes(this.props.model) ? "id" : "inscription_id"
                return (
                    <div style={cellStyle} className="border border-slate-600 px-2 py-1">
                        {this.props.action.map((act, actionIndex) => (
                            <Link
                                key={actionIndex}
                                to={`${act.link}${row[keySearch]}`}
                                target="_blank"
                            >
                                <IconButton color="info">
                                    {act.button === "view" && <VisibilityOutlinedIcon />}
                                    {act.button === "delete" && <DeleteOutlineOutlinedIcon />}
                                    {act.button === "update" && <BsFillTicketPerforatedFill />}
                                </IconButton>
                            </Link>
                        ))}
                    </div>
                );
            default:

                if (column.subfield && column.subfield != "") {
                    if (column.subfield == "avis") {
                        return <div style={cellStyle} className={`border border-slate-500 px-4 py-1 text-left text-md ${row['examin']['result'] >= 50 ? 'text-green-400' : 'text-red-400'}`}>
                            {
                                (row['examin']['result'] >= 50 ? 'pass' : 'Not pass')
                            }
                        </div>
                    }
                    return <div style={cellStyle} className={`border border-slate-500 px-4 py-1 text-left text-md ${column.color ? column.color : 'text-slate-300'}`} contentEditable={['speaking_mark', 'reading_mark', 'listening_mark', 'written_mark', 'evaluation_mark'].includes(column.subfield)} onBlur={(e) => this.props.change(e, row['examin']['id'], column.subfield, index)}>
                        {
                            (row['examin'][column.subfield])
                        }
                    </div>
                }
                return (
                    <div style={cellStyle} className={`border border-slate-600 px-4 py-1 ${column.color}`}>
                        {column.field === "id" ? index + 1 : fieldValue}
                    </div>
                );
        }
    };

    Row = ({ index, style }) => {
        const columns = this.props.columns;
        const rows = this.handleFilter(this.state.rows);
        const { columnWidths } = this.state;
        const row = rows[index];
        if (this.props.model && this.props.model == 'examain' && !row['examin'])
            return
        return (
            <div key={index} style={style} className="hover:bg-[#ffffff20] text-sm">
                {columns.map((column, colIndex) => (
                    <React.Fragment key={colIndex}>
                        {this.renderCell(row, column, index, columnWidths[colIndex])}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    render() {
        const { columns, rows } = this.props;
        return (
            <div className="my-5 h-[calc(100vh-260px)] flex justify-center">
                <div className="">
                    <div className="flex flex-row w-full text-slate-400 bg-[#00000020]">
                        {columns.map((column, index) => (
                            <div
                                key={index}
                                style={{ width: `${this.props.widths[index]}px`, minWidth: `${this.props.widths[index]}px`, color: column.color }}
                                className={`column-${index + 1} flex-auto px-2 py-3 text-left text-xs font-medium uppercase tracking-wider border border-slate-600 hover:cursor-pointer`}
                                onClick={() => this.handleSort(column.field)}
                            >
                                {column.use}
                            </div>
                        ))}
                    </div>
                    <List
                        height={500}
                        itemCount={this.handleFilter(rows).length}
                        itemSize={50}
                        width="100%"
                        className="flex flex-col parent-list-div text-slate-300 overflow-hidden"
                        itemData={this.handleFilter(rows)}
                    >
                        {this.Row}
                    </List>
                </div>
            </div>
        );
    }
}
