import React, { useContext, useRef, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import _R from "../../components/api";
import Sidebar from "../me/components/sidebar";
import { EditContext, EditProvider } from "../context/editContext"
import { BsCameraFill } from "react-icons/bs";
import Loading from "./Loading";

const Me = (props) => {
    const upload = useRef(null)
    const { me, setme, edit, picturePreview, setpicturePreview, setfile, setaccountInfo, setlangues, setData } = useContext(EditContext)
    const { user } = useContext(AuthContext)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setme(user.user)
        setLoading(false) 
    }, [])
    useEffect(() => {
        _R.get('/alllanguages').then((res) => {
            setlangues(res.data)
        })

    }, [])
    const handleChangePicture = (pic) => {
        setData({
            fields: [{
                name: 'picture',
                value: upload.current.files[0]
            }]
        })
        setfile(upload.current.files[0])
        setpicturePreview(pic)
    }
    return (
        !loading ?
            (!['admin', 'agent'].includes(user.role)) ?
                me ?
                    <>
                        <div className="bg-slate-50 w-full flex overflow-auto ">
                            <Sidebar />
                            <main className="ml-0 md:ml-[250px] pl-5 w-full mx-0 md:mx-auto">
                                <div className='m-5'>
                                    <div className="p-3 rounded-lg bg-pink-700 shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5">
                                        <h4 className="text-xl text-white font-bold">{(me.lastname).toUpperCase()}</h4>
                                        <p className="text-sm text-pink-300 font-[600] tracking-tight">{me.firstname}</p>
                                    </div>
                                    <div className="picture-profile">
                                        <div className="relative z-40">
                                            <div className="w-32 h-32 rounded-[50%] border-[10px] border-pink-700  rounded-tr-[8px] absolute -bottom-8  right-0 ">
                                                <div className="rounded-[50%] bg-white h-full p-2">
                                                    <div className="group rounded-[50%] overflow-hidden h-full" >
                                                        {
                                                            edit &&
                                                            <div className=" absolute top-0 left-0 rounded-[50%] h-full w-full grid place-content-center bg-[#13732b36] transition-all cursor-pointer "
                                                                onClick={() => {
                                                                    upload.current.click()
                                                                }}>
                                                                <BsCameraFill />
                                                            </div>
                                                        }
                                                        <img src={picturePreview ? picturePreview : me.picture ? `/en/images/users/${me.picture}` : `/en/images/users/default.png`} alt="" />
                                                    </div>
                                                    <input ref={upload} type="file" name="file" id="file" className="hidden" onChange={(e) => {
                                                        const objectUrl = URL.createObjectURL(e.target.files[0])
                                                        handleChangePicture(objectUrl)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        props.children
                                    }
                                </div>
                            </main>
                        </div>
                    </>
                    :
                    <div>
                        <Loading />
                    </div>
                :
                <Navigate to={'/en/admin/dashboard'} />
            :

            <div>
                <Loading />
            </div>
    )
}

export default Me