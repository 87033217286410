import { Box } from "@mui/material";
import Header from "../../components/Header";
import React, { useEffect, useState, useCallback, useContext, useRef } from "react";
import Table from "../../components/Table";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import _R from "../../components/api";
import Loading from "../layouts/Loading";
import { FilterContext } from "../context/filterContext"
import HeaderSection from "../global/headerSection";
import * as XLSX from 'xlsx';
import { AuthContext } from "../context/authContext";
import { use } from "react";

const Examain = (props) => {
    const session_id = useRef()
    const { filter } = useContext(FilterContext)
    const [nodeForm, setNodeForm] = useState(null)
    const [noode, setNoode] = useState(null)
    const [filterStudents, setFilterStudents] = useState(null)
    const [showModel, setShowModel] = useState(false)
    const [file, setFile] = useState(null)
    const [sessions, setSessions] = useState([])
    const [sessionShow, setSessionShow] = useState(null)
    const { user } = useContext(AuthContext)

    const [columns, setColumns] = useState([
        { field: "id", subfield: "", use: "ID", type: 'integer', show: true },
        { field: "inscription_id", subfield: "", use: "ins_id", type: 'hidden' },
        { field: "firstname", subfield: "", use: "First Name", type: 'string', show: true },
        { field: "lastname", subfield: "", use: "Last Name", type: 'string', show: true },
        { field: "group", subfield: "", use: "Group", type: 'string' },
        { field: "examin", subfield: "speaking_mark", use: "Speaking", type: 'string', color: "text-yellow-400", show: true },
        { field: "examin", subfield: "reading_mark", use: "Reading", type: 'string', color: "text-yellow-400", show: true },
        { field: "examin", subfield: "listening_mark", use: "Listening", type: 'string', color: "text-yellow-400", show: true },
        { field: "examin", subfield: "oral_mark", use: "Oral", type: 'string', color: "text-yellow-300" },
        { field: "examin", subfield: "written_mark", use: "Written", type: 'string', color: "text-yellow-300", show: true },
        { field: "examin", subfield: "evaluation_mark", use: "Evaluation", type: 'string', color: "text-yellow-300", show: true },
        { field: "examin", subfield: "result", use: "Result", type: 'string', color: "text-green-400" },
        { field: "examin", subfield: "avis", use: "Avis", type: 'string', color: "text-green-400" },
        { field: "action", subfield: "", use: "Action", type: 'button' }
    ])
    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, []);
    const form = useCallback(node => {
        if (node !== null) {
            setNodeForm(node);
        }
    }, [])
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await _R.get('/getStudents');
                if (response.data && Array.isArray(response.data)) {
                    const sessionss = await _R.get('/getSessions');
                    let data = (sessionss.data).reverse()
                    setSessions(data)
                    setSessionShow(data[0])
                    const newRows = response.data.filter(student => student.examin !== null);
                    setFilterStudents(newRows);
                } else {
                    console.warn('Unexpected data format:', response.data);
                }
            } catch (err) {
                //setError(err.message || 'Failed to fetch students.');
                console.error('Error fetching students:', err);
            }
        };

        fetchStudents();
    }, []);

    const loadSession = async () => {
        try {
            let id = session_id.current.value
            setSessionShow(null)
            setFilterStudents(null);
            const response = await _R.get('getExamains', { params: { id } });
            const newRows = response.data.filter((student) => student.examin !== null);
            setFilterStudents(newRows);
            setSessionShow(sessions.filter(session => session.id == id)[0]);
            console.log(sessions.filter(session => session.id == id)[0]);
        } catch (error) {
            console.error('Error fetching exam data:', error);
        }
    };
    const handleUpload = (e) => {
        console.log(e.target.files[0])
        setFile(e.target.files[0])
    }



    const handleChange = (e, id, type, index) => {
        let url = "/setExamain"
        let data = e.target.innerText
        try {
            _R.post(url, { id, type, data }).then(resp => {
                let d = [...filterStudents]
                d[index].examin = resp.data
                setFilterStudents(d)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleSetMarks = async () => {
        var formData = new FormData()
        formData.append('file', file)
        // formData.append('type', typeTest)
        try {
            const result = await _R.post('/setMarks', formData)
            window.location.reload()
        } catch (error) {
            console.log(error)
        }

    }
    const setProprJson = (rows) => {
        let data = [];
        rows.forEach((row) => {
            data.push(
                {
                    firstname: row.firstname,
                    lastname: row.lastname,
                    group: row.group,
                    speaking: row.examin ? row.examin.speaking_mark : null,
                    reading: row.examin ? row.examin.reading_mark : null,
                    listening: row.examin ? row.examin.listening_mark : null,
                    oral: row.examin ? row.examin.listening_mark : null,
                    written: row.examin ? row.examin.written_mark : null,
                    evaluation: row.examin ? row.examin.evaluation_mark : null,
                    result: row.examin ? row.examin.result : null,
                    avis: row.examin ? row.examin.avis : null,
                }
            )
        });
        return data;
    }
    const showHideColumns = [['FirstName', 1, true], ['LastName', 2, true], ['Language', 4, true]]

    const exportToExcel = () => {

        let rows = setProprJson(filterStudents);
        let header = ["First Name", "Last Name", "Group", "speaking", "reading", "listening", "oral", "written", "evaluation", "result", "avis"];
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [header]);
        XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
        const finalData = new Blob([excelBuffer], { type: "array" });
        XLSX.writeFile(wb, "Data.xlsx")
    }
    return (
        filterStudents ?
            <Box m="20px">
                <HeaderSection
                    table={{
                        name: "Examain",
                        headerTitle: "Examain",
                        headerSubTitle: "Manage all students .."
                    }}
                    download={true}
                    //noode={noode ? [{ node: noode, title: "Export Excel", sheet: "examins", filename: "examins" }, { node: nodeForm, title: "Export Excel form", sheet: "formulaire", filename: "formulaire" }] : null}
                    links={[{
                        setShowMode: setShowModel,
                        label: ' file Marks'
                    }]}
                    showHideColumns={{
                        showHideColumns: showHideColumns,
                        setColumns: setColumns,
                        columns: columns
                    }}
                    count={{ label: 'Demand', filterStudents }}
                    component={<button className="btn bg-cyan-600 p-2 text-white rounded-sm mt-3" onClick={() => exportToExcel()}>Export excel</button>}
                />
                <div className="flex justify-around mt-2 py-2 ">
                    <div className=" flex items-center">
                        {
                            sessionShow &&
                            <p className="text-cyan-600 opacity-50 text-2xl font-bold ">
                                {sessionShow.name} - {sessionShow.year}
                            </p>

                        }
                    </div>
                    {user.role === "admin" &&
                        <div className=" flex  justify-center">
                            <select ref={session_id} name="session_id" id="" defaultValue={sessionShow.id}>
                                {
                                    sessions && sessions.map((session, index) => {
                                        return (
                                            <option key={index} value={session.id} >{session.name} - {session.year}</option>
                                        )
                                    })
                                }
                            </select>
                            <button className=" text-white bg-green-500 mx-2 border-none px-4" onClick={() => loadSession()}>Go</button>
                        </div>
                    }
                </div>

                <div ref={form} className="hidden">
                    <table className="table w-full " >
                        <thead className=" bg-slate-700  text-left sticky -top-5 text-slate-400 z-10">
                            <tr >
                                {
                                    columns.map((column, index) => (
                                        column.show &&
                                        <th
                                            key={index}
                                            scope="col"
                                            className={`px-2 py-3 text-left text-sm font-medium text-slate-400 uppercase tracking-wider hover:cursor-pointer border border-slate-600 `}
                                        >
                                            {column.use}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className=" text-sm text-slate-100">
                            {
                                filterStudents.map((row, index) => (
                                    <tr key={index} className='hover:bg-[#ffffff20]'>
                                        <td>{row.examin ? row.examin.id : null}</td>
                                        <td>{row.firstname}</td>
                                        <td>{row.lastname}</td>
                                        <td>{row.birthday}</td>
                                        <td>{row.birthplace}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div ref={measuredRef}>
                    <Table
                        model='examain'
                        rows={filterStudents}
                        columns={columns}
                        setRows={setFilterStudents}
                        filter={filter}
                        change={handleChange}
                        widths={[50, 50, 150, 150, 170, 80, 80, 80, 80, 80, 80, 80, 80, 80]}
                        action={[
                            {
                                button: "view",
                                link: '/en/admin/pedagogy/examain/show/'
                            },
                        ]}
                    />
                </div>
                <>
                    <Modal
                        show={showModel}
                        size="md"
                        popup={true}
                        onClose={() => setShowModel(false)}
                    >
                        {<Modal.Header className="bg-slate-800" />}
                        <Modal.Body className="bg-slate-700 text-gray-400">
                            <div className=" space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                                <h3 className="text-lg font-medium pt-5 ">
                                    Select the level and let the application determine the group and time according to the chosen time.
                                </h3>
                                <div>
                                    <input onChange={(e) => handleUpload(e)} type="file" name="file" id="" />
                                </div>

                                <div className="w-full text-right">
                                    <button onClick={(e) => handleSetMarks(e)} className="btn bg-green-600 text-gray-100 hover:bg-green-700 ">
                                        Validate
                                    </button>
                                </div>

                            </div>
                        </Modal.Body>
                    </Modal>
                </>

            </Box> :

            <div>
                <Loading />
            </div>
    )
}

export default Examain
