import React from 'react'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import Header from '../../components/Header'
import { Link } from 'react-router-dom'

function HeaderSection(props) {
   const {table,component,links} = props
    return (
        <div className="bg-slate-800 p-3 border-l border-t border-slate-700 shadow-[2px_2px_5px_1px_#00000040]">
            <Header   title={table.headerTitle} subtitle={table.headerSubTitle} />
            <div className="text-right">
                {
                    links &&
                    links.map((l) => {
                        return (
                            <Link onClick={l.setShowMode} to="" className="btn bg-green-600 hover:bg-green-700 p-1 text-green-100 text-md mx-2" >
                                {l.label}
                            </Link>
                        )
                    })
                }
                {
                    component 
                }
                {
                    props.download && props.noode &&
                    props.noode.map((n) => {
                        return (
                            <DownloadTableExcel
                                filename={n.filename}
                                sheet={n.sheet}
                                currentTableRef={n.node}
                            >
                                <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md mx-2">{n.title}</button>
                            </DownloadTableExcel> 
                        )
                    })
                }
            </div>
            <div className="flex items-end justify-between"> 
                <div className="flex justify-start">
                    {props.showHideColumns && props.showHideColumns.showHideColumns.map((item) => {
                        return (
                            <div className="ml-2 text-slate-400 border border-slate-700 p-2 rounded-sm ">
                                <span className="mr-1">{item[0]}</span>
                                <input type="checkbox" name="" id="" defaultChecked={item[2]} onClick={(e) => {
                                    const col = [...props.showHideColumns.columns]
                                    col[item[1]].type = e.target.checked ? col[item[1]].origin : 'hidden'
                                    props.showHideColumns.setColumns(col)
                                }}
                                    className=' focus:outline-none clear-both ' />
                            </div>
                        )
                    })
                    }
                </div>
                {
                    props.count && props.count.filterStudents &&
                    <div>
                        <p className="text-slate-500 ml-2">
                            {props.count.filterStudents.length} - {props.count.label}
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}

export default HeaderSection