import React from 'react';
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from '../../context/authContext';
import { BsFillEnvelopeAtFill, BsFillLockFill, BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import NewInput from '../utils/NewInput';

function SignIn() {
  const { isAuth, login, loginError } = useContext(AuthContext);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const handleChange = (type) => {
    switch (type.label) {
      case 'email':
        setEmail(type.value);
        break;
      case 'password':
        setPassword(type.value);
        break;
      default:
        break;
    }
  }

  const handleLogin = () => {
    
    login(email, password)
  }

  return (!isAuth) ? (
    <div className="bg-white text-slate-800 min-h-screen overflow-hidden">
      <main className="text-gray-800 px-4 md:px-2 max-w-2xl mx-auto">
        <section className="relative">
          <div className="pt-20 pb-12 ">
            <div className=" mx-auto text-center pb-10">
              <div className="text-center py-5 mt-5 text-gray-600 rounded-t-md">
                <Link to={'/en'}>
                  <img className=" w-20  h-20 fill-current opacity-80 m-auto" src="./ceild.png" alt="" />
                </Link>
                <p className='text-2xl font-semibold mt-2'>Sign in to <span className='text-blue-700 font-bold text-3xl'> CEIL</span> </p>
              </div>
            </div>
            <div className="relative  rounded-lg shadow-[0_0_20px_1px_rgba(0,0,0,0.07)]">
              <div className="px-4 py-5 sm:p-6">
                <form>
                  {loginError && <p className=' left-0 bg-red-500 text-sm text-center font-semibold text-white py-2 mb-2 absolute -top-10 w-full'>{loginError}</p>}
                  <NewInput
                    type="text"
                    label={'Email address'}
                    id="email"
                    name="email"
                    class={'sm:col-span-3'}
                    icon={<BsFillEnvelopeAtFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                    onChange={handleChange}
                  //error={props.error}
                  />
                  <NewInput
                    type="password"
                    label={'Password'}
                    id="password"
                    name="password"
                    class={'sm:col-span-6'}
                    icon={<BsFillLockFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                    icon_show={<BsEyeFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                    icon_hide={<BsEyeSlashFill className="h-4 w-4 text-teal-500" aria-hidden="true" />}
                    onChange={handleChange}
                  //error={props.error}
                  />

                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <Link to="/en/reset-password" className=" text-xs  text-purple-600 hover:text-purple-700 transition duration-150 ease-in-out">Forgot Password?</Link>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button type='button' className="btn text-white bg-purple-600 hover:bg-purple-700 w-full rounded-md shadow-xl transition duration-150 ease-in-out"
                        onClick={() => handleLogin()
                        }>Sign in</button>
                    </div>
                  </div>
                  <div className=" text-center mx-auto relative py-3 px-5  text-lg">
                    Have you an account ?
                    <Link to="/en/signup"
                      className="font-bold  text-purple-600 hover:text-purple-700 transition duration-150 ease-in-out">
                      Sign up
                    </Link>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </section>
      </main>
    </div>) :
    (
      <Navigate to="/en" />
    )
}

export default SignIn;