import React, { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Header from './partials/Header';
import Sliders from './partials/Sliders';
import HeroHome from './partials/HeroHome';
import FeaturesBlocks from './partials/FeaturesBlocks';
import FeaturesZigZag from './partials/FeaturesZigzag';
import Testimonials from './partials/Testimonials';
import Footer from './partials/Footer';
import 'aos/dist/aos.css';
import './css/style.css';
import AOS from 'aos';
import Notification from '../global/Notification';
import { AuthContext } from '../context/authContext';
import { SettingsContext } from '../context/settingsContext';
import CompleteValide from './partials/CompleteValide';
import { RiUserSettingsFill } from "react-icons/ri";
import _R from "../../components/api";
import { BsShieldFillCheck } from 'react-icons/bs'
import Loading from "../layouts/Loading"
import { images_path } from '../../paths';
import { Carousel } from 'react-responsive-carousel';
import Modal from './utils/Modal';
import HeroImage from './images/student_reg.png';
import { videos_path } from '../../paths';

function Home() {
  const box = useCallback(node => {
    if (node !== null) {
      setNoode(node);
    }
  }, []);
  const sli = useCallback(node => {
    if (node !== null) {
      setNoodee(node);
    }
  }, []);
  //const box = createRef()
  //const sli = createRef()
  const { isAuth, user, login, loginError } = useContext(AuthContext)
  const { items, setItems, load, setLoad } = useContext(SettingsContext)
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [noode, setNoode] = useState(null)
  const [noodee, setNoodee] = useState(null)
  const [checkLogin, setCheckLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [left, setLeft] = useState(0)
  const [cacheleft, setCacheleft] = useState(false)
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [videoModal, setVideoModal] = useState(null);

  const handleChange = (type) => {
    console.log(items);
    switch (type.label) {
      case 'email':
        setEmail(type.value);
        break;
      case 'password':
        setPassword(type.value);
        break;
      default:
        break;
    }
  }

  const handleLogin = () => {
    setCheckLogin(true)
    login(email, password)
    setCheckLogin(false)
  }

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  }, [videoModal]);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]);

  useEffect(() => {
    _R.get('/settings').then((response) => {
      setItems(response.data)
      setLoading(false)
    })
  }, [])

  const handleVerifietMail = () => {
    setLoading(true)
    setShowNotification(false)
    _R.post('/verifietMail', { id: user.user.id }).then(res => {
      console.log(res.data)
      setShowNotification(true)
      setLoading(false)
    })
  }

  const styles = {
    transform: `translate(${-left}px,0)`,
    //: `${-left}px`,
  }

  return (
    !loading && items ?
      <div className=" bg-gray-200 text-gray-900  flex flex-col min-h-screen">
        <Header />
        <section>
          <div className="mb-4 text-center py-6 " style={{ background: `#0f172a url(${images_path}heade.png) no-repeat scroll 28% 10%`, borderRadius: "0px 0px 23% 23%/30%" }} >
            <div className='max-w-6xl mx-auto py-5'>
              <div className='flex md:flex-row flex-col text-center md:text-left justify-between items-center'>
                <div className='text-2xl md:text-3xl mx-2 font-bold'>
                  <h1 className="text-white " data-aos="fade-up">{items.header[0].content}</h1>
                  <p className="text-teal-400 mb-4" data-aos="fade-up" data-aos-delay="200">{items.header[1].content}</p>
                </div>
                <div >
                  <img className="w-80 fill-current text-purple-600" src={`${images_path}${items.header[2].content}`} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <main className="mx-0 lg:mx-auto  w-full lg:max-w-6xl  ">

          {
            !user &&
            <div className='text-center my-4'>
              <h6 className='text-xl md:text-md'>
                Don't have an account?
                <Link to={'/en/signup'} className='text-blue-500 font-bold ml-1'>
                  Sign Up
                </Link>
              </h6>
            </div>
          }
          <section className=' my-4 bg-white'>
            <div className='  w-full my-4 py-4  pb-2 px-2 relative ' >
              <div className='absolute  p-3 z-10 top-0 left-[-20px]  grid place-items-center h-full '>
                <span className=" font-extrabold text-lg px-4 rounded-[30px] text-white h-10 bg-[#474747] cursor-pointer leading-[40px] shadow-md hover:bg-[#717171] "
                  onClick={() => {
                    let pro = new Promise(resolve => {
                      //setLeft(left + 200)
                      if (!cacheleft) {
                        setCacheleft(true)
                        resolve(left - 200)

                      }
                    })

                    pro.then(l => {
                      console.log(l)
                      let boxx = noode.getBoundingClientRect().left
                      let slii = noodee.getBoundingClientRect().left + 200
                      if (boxx > slii) {
                        setLeft(l)
                        console.log(boxx, slii)
                        setCacheleft(false)
                      } else {
                        setLeft(0)
                        setCacheleft(false)
                      }
                    })
                  }}
                >{`<`}</span>
              </div>
              <div className='absolute  p-3 z-10 top-0 right-[-20px]  grid place-items-center h-full '>
                <span className="font-extrabold text-lg px-4 rounded-[30px] text-white h-10 bg-[#474747] cursor-pointer leading-[40px] shadow-md hover:bg-[#717171] "
                  onClick={(e) => {

                    let pro = new Promise(resolve => {
                      //setLeft(left + 200)
                      if (!cacheleft) {
                        setCacheleft(true)
                        resolve(left + 200)

                      }
                    })

                    pro.then(l => {
                      let boxxRight = noode.getBoundingClientRect().right
                      let sliiRight = noodee.getBoundingClientRect().right - 200
                      console.log(sliiRight)
                      if (boxxRight < sliiRight) {
                        setLeft(l)
                        setCacheleft(false)
                      } else {
                        let widthBox = noode.getBoundingClientRect().width
                        let widthSli = noodee.getBoundingClientRect().width
                        let xPosition = widthSli - widthBox
                        noodee.style.transform = `translate(-${xPosition}px, 0px)`
                        setCacheleft(false)
                      }
                    }
                    )


                  }}
                >{`>`}</span>
              </div>
              <div className='flex'>
                <div ref={box} className=' overflow-hidden'>
                  <div ref={sli} className={`m-0 p-0 whitespace-nowrap  transition-all duration-500 inline-flex`}
                    style={styles}
                  >
                    {
                      items.main.video.map((vid, index) => {
                        return (
                          <div key={index} className='inline-block px-1 align-top whitespace-normal'>
                            <ImageHero src={vid} setVideoModalOpen={setVideoModalOpen} setVideoModal={setVideoModal} />
                          </div>
                        )
                      })
                    }


                  </div>
                </div>

              </div>
            </div>
          </section>
          <section className=' my-4 bg-white'>
            <div className="flex">
              {
                !isAuth ?
                  <div className='bg-gray-100 relative w-full  mx-auto p-3 mb-4   h-full flex flex-col justify-around ' style={{ borderTop: '40px solid #3730a3' }}>
                    <h6 className='font-bold '>Authentication :</h6>
                    {loginError && <p className=' right-0 bg-red-500 text-sm text-center font-semibold text-white py-2 mb-2 absolute top-[2px] w-1/2'>{loginError}</p>}
                    <div className='my-1 '>
                      <input
                        onChange={(e) => handleChange({ label: 'email', value: e.target.value })}
                        className='w-full border border-slate-300 rounded-md p-2 font-semibold text-lg md:text-sm' type="email" name="email" id="email" placeholder='Email' />
                    </div>
                    <div className='my-1 '>
                      <input
                        onChange={(e) => handleChange({ label: 'password', value: e.target.value })}
                        className='w-full border border-slate-300 rounded-md p-2 font-semibold text-lg md:text-sm' type="password" name="password" id="password" placeholder='Password' />
                    </div>
                    <div className='mt-1 text-right'>
                      {
                        !checkLogin ?
                          <button
                            className='py-2 px-10 bg-green-600 hover:bg-green-700 text-white text-sm shadow-sm rounded-sm'
                            type="button"
                            onClick={() => handleLogin()}>
                            Sign in
                          </button>
                          :
                          <button
                            className='py-2 px-10 bg-green-400 text-white text-sm shadow-sm rounded-sm'
                            type="button" disabled={true}
                          >
                            Checking...
                          </button>
                      }

                    </div>
                  </div>
                  :
                  <div className='w-full  mx-auto p-3 mb-4 relative bg-gray-100 h-full text-center flex justify-around'>
                    <div>
                      <div className='w-3/4 mx-auto'>
                        <img lass="mx-auto" src={`${images_path}welcome1.png`} alt="" />
                      </div>
                      <h4 className='font-bold text-xl text-black  ml-[20%] md:ml-0  text-center'>Welcome 👋</h4>
                    </div>

                    <div className='flex flex-col justify-between p-4'>
                      <h6 className='font-bold text-[3rem] ml-[20%]  md:ml-0 text-[#31c57a] text-center items-center leading-9'>{user.user.firstname}</h6>
                      <p className='font-bold text-[1rem]  md:ml-0 text-green-700 text-center items-center leading-9'><span>{user.user.email}</span></p>
                      {user.user.status ?
                        <div className='flex'>
                          <Link to={(user.role != 'admin' && user.role != 'agent') ? '/en/me' : '/en/admin/dashboard'} className='text-white text-[24px] bg-teal-700 p-5 my-5 mx-auto hover:bg-teal-800 transition-all duration-100'><RiUserSettingsFill /></Link>
                        </div>
                        :
                        <div className="m-2 p-2 bg-[#ffdc8a]">
                          <p className="mb-2 text-lg font-bold ">
                            You must verifiet your address mail
                          </p>
                          <p className="mb-2 text-lg font-bold text-center ">
                            يرجى تأكيد بريدك الإلكتروني
                          </p>
                          <p className="mb-2 text-sm   text-center ">
                            سيتم إرسال رسالة تأكيد إلى بريدك الإلكتروني
                          </p>
                          <button
                            className=' py-2 px-10 bg-yellow-400 hover:bg-yellow-500 text-white text-sm shadow-sm rounded-sm'
                            type="button"
                            onClick={() => handleVerifietMail()}
                          >

                            <BsShieldFillCheck className='text-center mx-auto text-yellow-700 h-4 w-4 m-2' />
                            Send me a verification message
                          </button>
                        </div>

                      }
                    </div>
                  </div>
              }
            </div>
          </section>
          <section className=' my-4 bg-white'>
            <div className="w-[90%]  flex m-auto p-4">
              <Sliders images={items.main.slider} autoPlay={true} interval={4000} infiniteLoop={true} />
            </div>
          </section>
          <FeaturesBlocks />
          <FeaturesZigZag />
          <Testimonials />
        </main>
        <Footer />
        {showNotification &&
          <Notification
            info={
              {
                type: 'suc',
                message: 'Email verification sent successfully Please, Check your email '
              }

            }
          />
        }
        {
          <Modell
            videoModalOpen={videoModalOpen}
            setVideoModalOpen={setVideoModalOpen}
            src={videoModal}
          />
        }
      </div>
      :

      <div>
        <Loading />
      </div>
  );
}
export default Home;

export const ImageHero = ({ setVideoModalOpen, src, setVideoModal }) => {
  return (
    <div className=" relative flex justify-center items-center flex-col w-80" data-aos="fade-up" data-aos-delay="200">
      <img className="mx-auto  rounded-xl flex-auto  h-44" src={`${videos_path}${src.file}`} alt="Hero" />
      <a
        className="absolute group"
        data-srcVideo={src.content}
        href="#0"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setVideoModal(e.currentTarget.getAttribute('data-srcVideo'));
          setTimeout(() => {
            setVideoModalOpen(true);

          }, 100)
        }}
        aria-controls="modal"
      >
        <svg
          className="w-16 h-16 sm:w-20 sm:h-20 hover:opacity-75 transition duration-150 ease-in-out "
          viewBox="0 0 88 88"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient x1="78.169%" y1="9.507%" x2="24.434%" y2="90.469%" id="a">
              <stop stopColor="#EBF1F5" stopOpacity=".8" offset="0%" />
              <stop stopColor="#EBF1F5" offset="100%" />
            </linearGradient>
          </defs>
          <circle fill="url(#a)" cx="44" cy="44" r="44" />
          <path
            className="fill-current text-purple-600"
            d="M52 44a.999.999 0 00-.427-.82l-10-7A1 1 0 0040 37V51a.999.999 0 001.573.82l10-7A.995.995 0 0052 44V44c0 .001 0 .001 0 0z"
          />
        </svg>
      </a>
      <div className='p-1 text-left font-bold text-sm w-full'>
        <a
          className=""
          data-srcVideo={src.content}
          href="#0"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setVideoModal(e.currentTarget.getAttribute('data-srcVideo'));
            setTimeout(() => {
              setVideoModalOpen(true);

            }, 100)
          }}
          aria-controls="modal"
        > {src.title}</a>
      </div>
    </div>
  )
}

export const Modell = ({ videoModalOpen, setVideoModalOpen, src }) => {
  console.log(src)
  if (videoModalOpen) {
    document.querySelector("#modal > div > div > video").load()

  }
  return (
    <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen} handleClose={() => setVideoModalOpen(false)} style={{ 'background': 'transparent' }}>
      <div className=" pb-9/16" style={{
        'height': '100vh',
        'background': '#00000057',
        'display': 'grid',
        'placeItems': 'center'
      }}>
        <video
          className="absolute h-full"
          style={{
            'height': '100vh',
            background: 'black'
          }}
          controls
          autoPlay
        >
          <source src={`${videos_path}${src}`} type="video/mp4" />
        </video>
      </div>
    </Modal>
  )
}
