import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { AuthContext } from './scenes/context/authContext';
import { SettingsContext } from './scenes/context/settingsContext';
import { EditProvider } from './scenes/context/editContext';
import { FilterProvider } from './scenes/context/filterContext';
import Loading from './scenes/layouts/Loading';
import Home from './scenes/landing_page/home';
import SignIn from './scenes/landing_page/pages/SignIn';
import SignUp from './scenes/landing_page/pages/SignUp';
import ResetPassword from './scenes/landing_page/pages/ResetPassword';
import NewPassword from './scenes/landing_page/pages/newPassword';
import Verify from './scenes/mail';
import HomeLayout from './scenes/layouts/homeLayout';
import CompletePage from './scenes/landing_page/pages/CompletPage';
import MeRoutes from './routes/MeRoutes';
import AdminRoutes from './routes/AdminRoutes';
import _R from './components/BaseUrl'

const ProtectedRoute = ({ condition, redirectPath, children }) => {
    return condition ? children : <Navigate to={redirectPath} />;
};

function App() {
    const [sessi, setSessi] = useState(null);
    const {isAuth, setIsAuth, user, setUser } = useContext(AuthContext);
    const {  setLoad } = useContext(SettingsContext);


    useEffect(() => {
        const checkAuth = async () => {
            try {
                const res = await _R.get('/isAuth', {
                    headers: { "X_CEIL_TOKEN": localStorage.getItem('ceil_token') },
                });
                setIsAuth(true);
                setUser(res.data.userInfo);
                localStorage.setItem('ceil_token', res.data.token);
            } catch (error) {
                console.error('Authentication error:', error);
                setIsAuth(false);
                setUser(null);
            } finally {
                setLoad(false);

            }
        };
        checkAuth();
    }, []);
    useEffect(() => {
        const loadSession = async () => {
            try {
                const storedSession = localStorage.getItem('sessi');
                if (storedSession) {
                    setSessi(JSON.parse(storedSession));
                } else {
                    const response = await _R.get('/getSessionToShow', {
                        headers: { "X_CEIL_TOKEN": localStorage.getItem('ceil_token') },
                    });
                    setSessi(response.data);
                    localStorage.setItem('sessi', JSON.stringify(response.data));
                }
            } catch (error) {
                console.error('Error fetching session:', error);
            }
        };
        loadSession();
    }, []);

    if (isAuth === null) return <Loading color="bg-amber-700" />;

    return (
        <Routes>
            {/* Public Routes */}
            <Route
                path="/en"
                element={<Home />}
            />
            <Route
                path="/en/signin"
                element={<ProtectedRoute condition={!isAuth} redirectPath="/en"><SignIn /></ProtectedRoute>}
            />
            <Route
                path="/en/signup"
                element={<ProtectedRoute condition={!isAuth} redirectPath="/en"><SignUp /></ProtectedRoute>}
            />
            <Route path="/en/reset-password" element={<ResetPassword />} />
            <Route path="/en/new-password/:id/:token" element={<NewPassword />} />
            <Route path="/en/verifyMail/:id/:token" element={<Verify />} />
            {/* Authenticated Routes */}
            <Route
                path="/en/me/*"
                element={
                    <ProtectedRoute condition={isAuth && user.role !== 'admin' && user.role !== 'agent'} redirectPath="/en">
                        <MeRoutes />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/en/complete-registration"
                element={<ProtectedRoute condition={isAuth} redirectPath="/en/signin"><HomeLayout element={<CompletePage />} /></ProtectedRoute>}
            />
            {/* Admin Routes */}
            <Route
                path="/en/admin/*"
                element={<ProtectedRoute condition={isAuth && (user?.role == 'admin' || user?.role === 'agent')} redirectPath="/en">
                    <AdminRoutes session={sessi} user={user} />
                </ProtectedRoute>}
            />
        </Routes>
    );
}

export default App;
