import Roles from '../scenes/roles';
import Addrole from '../scenes/roles/add';
import Showrole from '../scenes/roles/show';
import Editrole from '../scenes/roles/edit';
import Permissions from '../scenes/permissions';
import Levels from '../scenes/levels';
import Langues from '../scenes/langues';
import Element from '../scenes/appearance';
import UpdateElementor from '../scenes/appearance/edit';
import Teachers from '../scenes/teachers';
import ShowStudent from '../scenes/students/show';
import StudentCard from '../scenes/students/pdf/StudentCard';
import Demand from '../scenes/demands';
import Showdemand from '../scenes/demands/show';
import TicketA from '../scenes/landing_page/pages/TicketA';
import ShowGroup from '../scenes/groups/show';
import Examain from '../scenes/examain';
import ShowExamain from '../scenes/examain/show';
import Users from '../scenes/users';
import ShowUser from '../scenes/users/show';
import Adduser from '../scenes/users/add';
import Dashboard from '../scenes/dashboard';
import Groups from '../scenes/groups';
import Students from '../scenes/students';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import QuizIcon from '@mui/icons-material/Quiz';
import BrushIcon from '@mui/icons-material/Brush';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import LayersIcon from '@mui/icons-material/Layers';
import RoleIcon from '@mui/icons-material/AccountTree';

export const adminRoutesData = [
    {
        routes: [
            {
                path: "dashboard",
                element: <Dashboard />, 
                showMenu: true, 
                icon: <DashboardIcon />
            }
        ],
        permission: "dashboard"
    },
    {
        routes: [
            {
                path: "users",
                element: <Users />, 
                showMenu: true, 
                icon: <PeopleIcon />
            },
            {
                path: "users/show/:id",
                element: <ShowUser />, 
                showMenu: false, 
                icon: "icon-show-user"
            },
            {
                path: "users/add",
                element: <Adduser />, 
                showMenu: false, 
                icon: "icon-add-user"
            }

        ],
        permission: "users"
    },
    {
        routes: [
            {
                path: "demands",
                element: <Demand />, 
                showMenu: true, 
                icon: <AssignmentIcon />
            },
            {
                path: "demands/show/:id",
                element: <Showdemand />, 
                showMenu: false, 
                icon: "icon-show-demand"
            },
            {
                path: "demands/ticket/:inscription_id",
                element: <TicketA />, 
                showMenu: false, 
                icon: "icon-ticket"
            }

        ],
        permission: "demands"
    },
    {
        routes: [
            {
                path: "students",
                element: <Students />, 
                showMenu: true, 
                icon: <SchoolIcon />
            },
            {
                path: "students/show/:id",
                element: <ShowStudent />, 
                showMenu: false, 
                icon: "icon-show-student"
            },
            {
                path: "card/:name/:language/:address",
                element: <StudentCard />, 
                showMenu: false, 
                icon: "icon-student-card"
            }

        ],
        permission: "students"
    },
    {
        routes: [{
            path: "teachers",
            element: <Teachers />, 
            showMenu: true, 
            icon: <PersonIcon />
        }],
        permission: "teachers"
    },
    {
        routes: [
            {
                path: "pedagogy/groups",
                element: <Groups />, 
                showMenu: true, 
                icon: <GroupIcon />
            },
            {
                path: "pedagogy/groups/show/:id",
                element: <ShowGroup />, 
                showMenu: false, 
                icon: "icon-show-group"
            }

        ],
        permission: "groups"
    },
    {
        routes: [
            {
                path: "pedagogy/examain",
                element: <Examain />, 
                showMenu: true, 
                icon: <QuizIcon />
            },
            {
                path: "pedagogy/examain/show/:id",
                element: <ShowExamain />, 
                showMenu: false, 
                icon: "icon-show-examain"
            }

        ],
        permission: "examains"
    },
    {
        routes: [{
            path: "appearance",
            element: <Element />, 
            showMenu: true, 
            icon: <BrushIcon />
        }, {
            path: "appearance/update",
            element: <UpdateElementor />, 
            showMenu: false, 
            icon: "icon-appearance-update"
        }],
        permission: "appearance"
    },
    {
        routes: [{
            path: "settings/langues",
            element: <Langues />, 
            showMenu: true, 
            icon: <LanguageIcon />
        }],
        permission: "langues"
    },
    {
        routes: [{
            path: "settings/permissions",
            element: <Permissions />, 
            showMenu: true, 
            icon: <SecurityIcon />
        }],
        permission: "permissions"
    },
    {
        routes: [{
            path: "settings/levels",
            element: <Levels />, 
            showMenu: true, 
            icon: <LayersIcon />
        }],
        permission: "levels"
    },
    {
        routes: [
            {
                path: "settings/roles",
                element: <Roles />, 
                showMenu: true, 
                icon: <RoleIcon />
            },
            {
                path: "settings/roles/add",
                element: <Addrole />, 
                showMenu: false, 
                icon: "icon-add-role"
            },
            {
                path: "settings/roles/show/:id",
                element: <Showrole />, 
                showMenu: false, 
                icon: "icon-show-role"
            },
            {
                path: "settings/roles/edit/:id",
                element: <Editrole />, 
                showMenu: false, 
                icon: "icon-edit-role"
            }

        ],
        permission: "roles"
    },
    {
        routes: [
            {
                path: "./",
                element: <Dashboard />, 
                showMenu: false, 
                icon: "icon-dashboard"
            }
        ],
        permission: "dashboard"
    }
];


