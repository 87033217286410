import { Autocomplete, Box, Button, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { tokens } from "../../theme"
import { useState } from "react"
import Header from "../../components/Header"
import { Formik } from "formik";
import _R from "../../components/api";
import { useEffect } from "react";
import React, { Component }  from 'react';


const Levels = () => {

    useEffect(() => {
        _R.get('/alllevels').then((res) => {
            setAlllevels(res.data)
            setLevels(res.data)
            console.log(res)
            initValuesEdit.levels=res.data
        })
    }, [])

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [alllevels, setAlllevels] = useState([])
    const [levels, setLevels] = useState([])
    const isNonMobile = useMediaQuery("(min-width:700px)")
    const initValues = {
        level: ""
    }
    const initValuesEdit = {
        levels: levels
    }
    const handleFormSubmit = (values) => {
        _R.post('/addlevel', values).then((res) => {
            setAlllevels(res.data)
            setLevels(res.data)
            console.log(res)
        })
    }
    const handleFormSubmitEdit = (values) => {
        // axios.post('/addlevel',values).then((res)=>{
        console.log(levels)
        // })
    }
    const handleChangeSelect = (e, value) => {
        setLevels(value)
        console.log(value)
    }

    return (
        <Box
            m="20px"
        >
            <Header title="LEVELS" subtitle="All levels" />
            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    m: "20px",
                }}
            >
                <Box sx={{ gridColumn: "span 2" }}>
                    <Formik
                        onSubmit={handleFormSubmitEdit}
                        initialValues={initValuesEdit}
                    >
                        {
                            ({
                                values,
                                handleSubmit,
                                handleChange
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Typography>
                                        All Levels :
                                    </Typography>
                                    <Autocomplete
                                        multiple
                                        value={levels}
                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                        options={alllevels}
                                        getOptionLabel={(option) => option.name}
                                        sx={{ display: "flex", alignItems: 'flex-end' }}
                                        onChange={handleChangeSelect}
                                        renderInput={(params) => (
                                            <>
                                                <TrendingUpIcon sx={{ color: `${colors.primary[300]}`, mr: 1, my: 0.5 }} />
                                                <TextField
                                                    {...params}
                                                    name="levels"
                                                    variant="standard"
                                                    value={values}
                                                    //label="Levels"
                                                    placeholder="Levels"
                                                />
                                            </>
                                        )}
                                    />
                                    <Box display="flex" justifyContent="end" mt="20px">
                                        <Button type="submit" color="success" variant="contained">
                                            Update
                                        </Button>
                                    </Box>
                                </form>
                            )
                        }

                    </Formik>
                </Box>
                <Box sx={{ gridColumn: "span 2" }}>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initValues}
                    >
                        {
                            ({
                                values,
                                handleSubmit,
                                handleChange
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="flex" flexDirection="column"
                                    >
                                        <Typography>
                                            Create Level :
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name="level"
                                            variant="standard"
                                            //label=" "
                                            placeholder="new Level"
                                            onChange={handleChange}
                                        />
                                        <Box display="flex" justifyContent="end" mt="20px">
                                            <Button type="submit" color="success" variant="contained">
                                                Add
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            )
                        }

                    </Formik>
                </Box>
            </Box>
        </Box>
    )
}

export default Levels