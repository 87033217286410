import { useContext, useState } from "react"
import React from 'react';
import { IconButton } from "@mui/material"
import { Link } from "react-router-dom"
import { useMode, themeSettings } from "../../theme"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import { SettingsContext } from "../context/settingsContext";
import { adminRoutesData } from "../../routes/adminRoutesData";
import { AuthContext } from "../context/authContext";

const Sidebare = (props) => {
    const basename = 'https://ceil.univ-msila.dz/en'
    const { mode } = useMode()
    const theme = themeSettings(mode)
    const colors = theme.palette
    const { isCollapsed, setIsCollapsed} = useContext(SettingsContext)
    const { permissions } = useContext(AuthContext)
    const [selected, setSelected] = useState("Dashboard")
    return (
        colors &&
        <div
            style={{ backgroundColor: colors.background.sidebar }}
            className={` fixed left-0 top-0 z-[60] h-[100vh]  bg-slate-900 transition-["width"]  ${isCollapsed ? 'w-[50px] delay-300 bg-transparent' : 'w-[250px]  '}`}>
            <div
                className=" w-full bg-slate-950">
                <div
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                    className={isCollapsed ? 'p-0 flex' : 'p-0 flex'}
                    style={{ background: colors.background.sidebarHead }}

                >
                    {!isCollapsed && (
                        <div
                            className=" h-[70px] flex justify-between items-center ml-4 flex-auto"
                        >
                            <div className="flex justify-between flex-auto">
                                <p
                                    style={{ color: colors.text.sidebar }}
                                >Ceil Msila
                                </p>
                                <p
                                    style={{ color: colors.text.sidebar }}
                                ><small>Admin console</small>
                                </p>
                            </div>

                            <IconButton
                                style={{ color: colors.text.sidebar }}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        </div>
                    )}
                    {isCollapsed && (
                        <div
                            className=" h-[70px] flex justify-center items-center flex-auto"
                        >
                            <IconButton
                                style={{ color: colors.text.sidebar }}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div>
                    {!isCollapsed && (
                        <div >

                            <div className={`text-center p-5 ${!props.session.archived ? 'text-cyan-500' : 'text-gray-200'} `}>
                                <h1 className="text-5xl font-extrabold opacity-50">{props.session.name}</h1>
                                <h1 className="text-4xl font-extrabold opacity-30">{props.session.year}</h1>
                            </div>
                            <div className="h-[100px] flex justify-center items-center" >
                                <Link to="/en">
                                    <img
                                        src={`${basename}/assets/logo.jpg`}
                                        alt="profile"
                                        width="70px"
                                        height="70px"
                                        style={{ cursor: "pointer", borderRadius: "50%" }}
                                    />
                                </Link>
                            </div>
                        </div>
                    )}
                    <div
                        className={`h-[calc(100vh_-_170px)]  pb-5 transition-['display'] ${isCollapsed ? "hidden" : "overflow-auto delay-1000"}`}
                    >
                        {
                            adminRoutesData.map((ele, ind) => {
                                return (
                                    permissions.includes(ele.permission) &&
                                    ele.routes.map((Route, index) => {
                                        if (!(Route.showMenu))
                                            return
                                        return (
                                            <Item
                                                key={`${ele.permission}-${Route.path}`}
                                                title={ele.permission}
                                                to={`/en/admin/${Route.path}`}
                                                icon={Route.icon}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        )
                                    })



                                )
                            })
                        }

                    </div>
                </div>
            </div>

        </div>

    )
}


const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useMode();
    const colors = themeSettings(theme.mode).palette;

    const { setIsCollapsed } = useContext(SettingsContext);

    const baseClass = `p-2 pl-4 w-full flex items-center transition-all duration-300 text-gray-300`;
    const selectedClass = selected === title ? ' text-yellow-400' : '';
    const hoverClass = `hover:text-yellow-500 `;

    return (
        colors && (
            <Link to={to} className="hover:text-yellow-500" style={{ textDecoration: 'none' }}>
                <button
                    onClick={() => {
                        setSelected(title);
                        setIsCollapsed(true);
                    }}
                    className={`${baseClass} ${selectedClass} ${hoverClass}`}
                >
                    {icon}
                    <p className={`ml-4 font-light`}>{title.charAt(0).toUpperCase() + title.slice(1)}</p>
                </button>
            </Link>
        )
    );
};


export default Sidebare