import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Me from '../scenes/layouts/me';
import MeIndex from '../scenes/me';
import NewDemand from '../scenes/me/NewDemand';
import EditDemand from '../scenes/me/edit';
import ReRegistre from '../scenes/me/reregistre';
import ShowCourse from '../scenes/me/show';
import { EditProvider } from '../scenes/context/editContext';

const MeRoutes = () => {
    return (
        <EditProvider>
            <Me>
                <Routes>
                    <Route path="/" element={<MeIndex />} />
                    <Route path="/makeDemand" element={<NewDemand />} />
                    <Route path="/editDemand" element={<EditDemand />} />
                    <Route path="/re-registre/:id" element={<ReRegistre />} />
                    <Route path="/:id" element={<ShowCourse />} />
                </Routes>
            </Me>
        </EditProvider>
    );
};

export default MeRoutes;
