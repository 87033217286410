import { Box } from "@mui/material";
import Header from "../../components/Header";
import { Link } from "react-router-dom"
import React, { useEffect, useState, useContext, useCallback } from "react";
import Table from "../../components/Table";
import _R from "../../components/BaseUrl";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Loading from "../layouts/Loading"
import { FilterContext } from "../context/filterContext"

const Groups = (props) => {
    const measuredRef = useCallback(node => {
        if (node !== null) {
            setNoode(node);
        }
    }, []);
    useEffect(() => {
        _R.get('/getGroups').then((res) => {
            setFilterGroups(res.data)
        })
    }, [])

    const { filter } = useContext(FilterContext)
    const [filterGroups, setFilterGroups] = useState(null)
    const [noode, setNoode] = useState(null)

    const [columns, setColumns] = useState([
        { field: "id", use: "ID", type: "integer" },
        { field: "name", use: "Name", type: "string" },
        { field: "level", use: "Level", type: "string", color: 'text-yellow-400 font-bold' },
        { field: "langue", use: "Langue", type: "string" },
        { field: "time", use: "Study Time", type: "string" },
        { field: "room", use: "Room", type: "string", color: 'text-cyan-400 font-bold' },
        { field: "count", use: "Nbr Students", type: "string", color: 'text-green-500 font-bold text-center text-lg' },
        { field: "action", use: "Action", type: "button" }
    ]);
    return (
        filterGroups ?
            <Box m="20px">
                <Header title="GROUPS" subtitle="Manage all groups .." />
                <Box textAlign="right">
                    <DownloadTableExcel
                        filename="groups table"
                        sheet="groups"
                        currentTableRef={noode}
                    >
                        <button className=" btn bg-blue-600 hover:bg-blue-700 p-1 text-blue-100 text-md"> Export excel </button>
                    </DownloadTableExcel>
                </Box>
                <div ref={measuredRef}>
                    <Table
                        model='groups'
                        rows={filterGroups}
                        columns={columns}
                        setRows={setFilterGroups}
                        filter={filter}
                        widths={[50, 250, 150, 150, 250, 150, 150, 150]}
                        action={[
                            {
                                "button": "view",
                                "link": '/en/admin/pedagogy/groups/show/'
                            },
                        ]}
                    />
                </div>
            </Box>
            :
            <div>
                <Loading />
            </div>
    )
}

export default Groups
