import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../context/authContext";
import _R from "../../components/api";
import { EditContext } from "../context/editContext"
import { SettingsContext } from "../context/settingsContext"
import TextInp from "./components/textInput"
import { Block_course, Block_demand } from "./components/blocks"
import { Link } from "react-router-dom"
import Notification from '../global/Notification';
import Loading from '../layouts/Loading';

String.prototype.toUpperFirstCase = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

const MeIndex = () => {
    const [showNotification, setShowNotification] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [deadline, setDeadline] = useState(false);


    const { me, inscriptions, setinscriptions, editable_data, initialize, edit, setedit, picturePreview, file, accountInfo, setData } = useContext(EditContext)
    const { user } = useContext(AuthContext)
    const { load, setload, items } = useContext(SettingsContext)

    useEffect(() => {
        initialize() 
        _R.get('/getInscriptions', { params: { id: user.user.student_id } }).then((res) => {
            setinscriptions(res.data)

            _R.get('/getdeadlines').then((res) => {
                setDeadline(res.data.isInsOpen)
                setLoading(false)
            })
        })
    }, [])
    useEffect(() => {

    }, [])

    const handleClick = () => {
        !edit ?
            setedit(true)
            :
            (
                handleSave()
            )
    }

    const createForm = () => {
        return new Promise(res => {
            let formData = new FormData()
            formData.append('id', user.user.id)
            formData.append('document_status', 2)
            let data = {
                id: user.user.id,
                fields: {},
            }
            let promises = []
            if (editable_data) {
                editable_data.fields.forEach(f => {
                    promises.push(
                        new Promise((resolve, reject) => {
                            formData.append(f.name, f.value)
                            data["fields"][f.name] = f.value
                            resolve()
                        })
                    )
                })
            }
            Promise.all(promises).then(() => {
                if (picturePreview) {
                    const reader = new FileReader()
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        res(formData)
                    }
                } else {
                    res(formData)
                }
            })
        })
    }

    const handleSave = async () => {
        try {
            setLoading(true)
            setShowNotification(false)
            let form = await createForm()
            let res = await _R.post('/updateUserMe', form)
            window.location.reload()
        } catch (error) {
            initialize()
            setLoading(false)
            let err = error.response.data.errors
            setError('Something wrong!')
            setShowNotification(true)
        }
    }
    return (
        !loading ?
            <>
                <div className="">
                    {
                        me && !me.picture &&
                        <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5 bg-red-300">
                            You must set your picture
                        </div>
                    }
                    {
                        me && me.idc1 == null &&
                        <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5 bg-red-300">
                            You must set your identity card first facade
                        </div>
                    }
                    {
                        me && me.idc2 == null &&
                        <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5 bg-red-300">
                            You must set your identity card second facade
                        </div>
                    }
                    {
                        me && me.document_status == 0 &&
                        <div className="p-3  mb-5 ">
                            <span className="p-1 rounded-md bg-green-400 text-white text-sm">Approved account</span>

                        </div>
                    }
                    {
                        me && me.document_status == 1 &&
                        <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5 bg-red-400">
                            Your account Validation is rejected : {me.observation}
                        </div>
                    }
                    {
                        me && me.document_status == 2 &&
                        <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5 bg-yellow-300">
                            Your account is under review : {me.observation}
                        </div>
                    }

                    <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5">
                        <h6 className="text-xl text-sky-700 m-2 my-4 font-bold">General information</h6>
                        <div className="flex flex-col lg:flex-row ">
                            <TextInp label="First name" value={me.firstname} edit={edit} name={'firstname'} />
                            <TextInp label="Last name" value={me.lastname} edit={edit} name={'lastname'} />
                            <TextInp label="Gendre" value={me.gendre} type={'select'} edit={edit} values={gendreSelect} name={'gendre'} />
                        </div>
                        <div className="flex flex-col lg:flex-row ">
                            <TextInp label="Email" value={me.email} css={'basis-1/2'} name={'email'} />
                            <TextInp label="Phone" value={me.phone} css={'basis-1/2'} edit={edit} name={'phone'} />
                        </div>
                        <div className="flex flex-col lg:flex-row ">
                            <TextInp label="Birth day" value={me.birthday} css={'basis-1/2'} type={'date'} edit={edit} name={'birthday'} />
                            <TextInp label="Birth place" value={me.birthplace} css={'basis-1/2'} edit={edit} name={'birthplace'} />
                        </div>
                        <div className="flex flex-col pb-3 lg:flex-row ">
                            <TextInp label="Address" value={me.address} css={'flex-auto'} edit={edit} name={'address'} />
                        </div>
                        {
                            edit && me &&
                            <div class="col-span-6 sm:col-span-6 p-2 ">
                                <label class=" text-sm text-gray-400 ">* recto facade of your identity card </label>
                                <input type="file" name="file1" id="file1" className="" onChange={(e) => {
                                    setData({
                                        fields: [{
                                            name: 'facade1',
                                            value: e.target.files[0]
                                        }]
                                    })
                                }} />
                            </div>
                        }
                        {
                            edit && me &&
                            <div class="col-span-6 sm:col-span-6 p-2 ">
                                <label class="mb-2 text-sm text-gray-400 ">* verso facade of your identity card </label>
                                <input type="file" name="file2" id="file2" className="" onChange={(e) => {
                                    setData({
                                        fields: [{
                                            name: 'facade2',
                                            value: e.target.files[0]
                                        }]
                                    })
                                }} />
                            </div>
                        }
                        {
                            me && me.document_status != 0 &&

                            <div className="text-right m-2">
                                <button
                                    className={`w-32 py-2 px-3  text-white transition-all text-md shadow-[0_0_10px_1px_#00000030] ${edit ? 'bg-green-600 hover:bg-green-700' : 'bg-sky-600 hover:bg-sky-700'}`}
                                    onClick={() => handleClick()}
                                >
                                    {
                                        edit ? 'Confirm ' : 'Update'
                                    }
                                </button>
                            </div>
                        }
                    </div>
                    <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5">
                        <h6 className="text-xl text-sky-700 m-2 my-4 font-bold">My demands</h6>
                        <div className=" p-3">
                            <div className="flex justify-start mb-4">
                                {
                                    deadline ?
                                        me.document_status != 1 ?
                                            me && me.document_status == 0 ?
                                                (inscriptions && (inscriptions.currentData).length == 0 ?
                                                    <>
                                                        <Link to={`/en/me/makeDemand`} className="border-none px-5 py-2 bg-sky-500 text-white text-sm">Make a new demand</Link>
                                                        {
                                                            (inscriptions.data).length ?
                                                                <Link to={`/en/me/re-registre/${inscriptions.data[0].id}`} className="border-none px-5 py-2 bg-green-500 text-white text-sm ml-5">Re-registration</Link>
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                )
                                                :
                                                <>
                                                </>
                                            :
                                            <>
                                            </>
                                        :
                                        <>
                                            <p>Inscription closed</p>
                                            <p>التسجيلات مغلقة</p>
                                        </>
                                }
                            </div>
                            <hr />
                            <div className="flex  md:flex-row flex-col mt-4">
                                {
                                    me && me.document_status == 0 && inscriptions && (inscriptions.currentData).length ?
                                        <>
                                            {
                                                inscriptions.currentData.map((demand) => {
                                                    return (
                                                        <Link to={`/en/me/editDemand`}>
                                                            <Block_demand demand={demand} />
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <p className="font-bold text-gray-600 text-sm ml-2">no data...</p>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="p-3 rounded-lg shadow-[0px_0px_20px_1px_rgba(0,0,0,0.1)] mb-5">
                        <h6 className="text-xl text-sky-700 m-2 my-4 font-bold">My Sessions</h6>
                        <div className="flex  md:flex-row flex-col p-3">
                            {
                                inscriptions && (inscriptions.data).length ?
                                    <>

                                        {
                                            inscriptions.data.map((demand) => {
                                                return (
                                                    <Block_course demand={demand} />
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <p className="font-bold text-gray-600 text-sm ml-2">no data...</p>
                            }
                        </div>
                    </div>
                </div>

                {showNotification &&
                    <Notification
                        info={
                            {
                                type: 'err',
                                message: error
                            }

                        }
                    />
                }
            </>
            :


            <div>
                <Loading />
            </div>

        //<div style={{display:'grid',placeItems:'center'}} className='text-gray-500 text-xl font-semibold bg-white pt-[30px]'>Waiting...</div>


    )
}

const gendreSelect = [
    {
        key: 0,
        value: 'Male'
    }, {
        key: 1,
        value: 'Female'
    }
]

export default MeIndex