import { Box, IconButton, useTheme } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useMode, tokens, themeSettings } from "../../theme"
import InputBase from "@mui/material/InputBase"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import SearchIcon from "@mui/icons-material/Search"
import React, { Component } from 'react';
import { FilterContext } from "../context/filterContext"


const Topbar = (props) => {
    const { setFilter } = useContext(FilterContext)
    const { mode, toggleColorMode } = useMode()
    const theme = themeSettings(mode)
    const colors = theme.palette
    return (
        colors &&
        <div
            style={{
                backgroundColor: colors.background.header
            }}
            className={` flex justify-between p-2 h-[70px]  sticky top-0 z-50  shadow-[0_2px_15px_1px_#00000040] `}
        >
            <div
                style={{
                    color: colors.text.header
                }}
                className="flex bg-[#00000050] rounded-md sticky"
            >
                <InputBase sx={{ ml: 2, flex: 1, color: colors.text.header }} placeholder="Search" onChange={(e) => setFilter(e.target.value)} />
                <IconButton type="button" sx={{ p: 1, color: colors.text.header }}>
                    <SearchIcon />
                </IconButton>
            </div>
            <div
                style={{
                    color: colors.text.header
                }}

                className="flex items-center">
                <button
                    className={`inline-flex items-center justify-center ${colors.text.header}`} onClick={toggleColorMode}>
                    {
                        mode === "dark" ? (
                            <DarkModeOutlinedIcon />
                        ) : <LightModeOutlinedIcon />
                    }
                </button>
                <button className={`inline-flex items-center justify-center ${colors.text.header} `} onClick={toggleColorMode}>

                    <NotificationsOutlinedIcon />
                </button>

                <button className={`inline-flex items-center justify-center ${colors.text.header} `} onClick={toggleColorMode}>

                    <SettingsOutlinedIcon />
                </button>

                <button className={`inline-flex items-center justify-center text-[${colors.text.header}] `} onClick={toggleColorMode}>

                    <PersonOutlineIcon />
                </button>
            </div>
        </div>

    )
}

export default Topbar